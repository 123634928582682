import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import IconWarning from '../../components-v2/icons/sources/warning';

const namespace = 'ui-search-warning';

const Warning = ({ message, className = null }) => (
  <div className={classnames(namespace, className)}>
    <div className={`${namespace}__message`}>
      <IconWarning className={`${namespace}__icon`} />
      <p className={`${namespace}__label`}>{message}</p>
    </div>
  </div>
);

Warning.propTypes = {
  className: string,
  message: string.isRequired,
};

export default Warning;
