/**
 * Module dependencies
 */
const React = require('react');
const { shape } = require('prop-types');

const { Style } = require('nordic/style');
const RedirectApp = require('redirect-app-web');

const MainRescue = require('./main.rescue').default;
const SpriteWarning = require('../../../../../components-v2/icons/svg-sprites/sprites/sprite-warning').default;
const Metadata = require('../../../../../components/metadata').default;
const Rescue = require('../../../../../components/rescue/rescue.mobile').default;

/**
 * Adults View
 */

const SearchRescue = ({ initialState, openInApp }) => (
  <MainRescue>
    <svg style={{ display: 'none' }}>
      <SpriteWarning />
    </svg>
    <div className="ui-search--zrp">
      {openInApp?.shouldRedirect && <RedirectApp {...openInApp} />}
      <Style href="search.rescue.mobile.css" critical />
      <Style href="search.rescue.mobile.css" />
      <Metadata {...initialState.canonical_info} title={initialState.seo.title} noIndex />
      <Rescue {...initialState.rescue} results={initialState.results} shops={initialState.shops} />
    </div>
  </MainRescue>
);

SearchRescue.propTypes = {
  initialState: shape({}),
  openInApp: shape({}),
};

SearchRescue.defaultProps = {
  initialState: null,
  openInApp: null,
};

module.exports = SearchRescue;
