import React, { useEffect } from 'react';
import { string, shape, arrayOf } from 'prop-types';

import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Warning from '../warning/warning.mobile';
import Labels from './components/labels';
import Actions from './components/actions';
import { trackSellerPixelsInfo } from '../../lib/tracking';

const namespace = 'ui-search-rescue';

const Rescue = ({ shops, results, message, labels, actions, type }) => {
  useEffect(() => {
    if (shops && window.tracking_mediator) {
      trackSellerPixelsInfo(window.tracking_mediator, results);
    }
  }, [results, shops]);

  return (
    <div className={classnames(namespace, `${namespace}--${type}`)}>
      <Warning message={message.label.text} />
      {!isEmpty(labels) && <Labels labels={labels} />}
      {actions && <Actions actions={actions} />}
    </div>
  );
};

Rescue.propTypes = {
  actions: arrayOf(
    shape({
      type: string,
      target: string,
      label: shape({
        text: string,
      }),
    }),
  ),
  labels: arrayOf(
    shape({
      text: string,
    }),
  ).isRequired,
  message: shape({
    label: shape({
      text: string,
    }),
  }).isRequired,
  results: arrayOf(shape({})),
  shops: string,
  type: string.isRequired,
};

Rescue.defaultProps = {
  actions: null,
  results: null,
  shops: '',
};

export default Rescue;
