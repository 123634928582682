import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const ICON_ID = 'ic_warning';

const IconWarning = (props) => {
  const prefixClass = props.prefixClass ?? 'ui-search';
  const namespace = `${prefixClass}-icon ${prefixClass}-icon--warning`;

  return (
    <BuildIcon
      {...props}
      className={classnames(namespace, props.className)}
      viewBox="0 0 1792 1792"
      iconId={ICON_ID}
      width="50"
      height="50"
    />
  );
};

IconWarning.propTypes = {
  className: string,
  prefixClass: string,
  style: string,
};

export default IconWarning;
